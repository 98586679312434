@import "../../../../../assets/style/media.less"; // 媒体查询
@import "../../../../../assets/style/classname.less"; // 类名
@iotStatisticPrefix: ~'.iot-component-description-group';

@{iotStatisticPrefix} {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 8px 36px 0 36px;
}
