@import "../../../../../assets/style/media.less"; // 媒体查询
@import "../../../../../assets/style/classname.less"; // 类名
@iotStatisticPrefix: ~'.iot-component-statistic';

@{iotStatisticPrefix} {
  display: flex;
  flex-direction: column;
  align-items: center;
  &-content {
    margin-bottom: 2px;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
  }
  &-title {
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
  }
}
