@import "../../../assets/style/media.less"; // 媒体查询
@import "../../../assets/style/classname.less"; // 类名
// @import '../variable.less';
@cardHeight-1: 180px;
@cardHeight-2: 114px;

@{TWBaseMenuProCardPrefixCls} {
  background-color: #fff;
  height: @cardHeight-1; // default height
  &.ant-card {
    border-radius: 8px;
    border: 3px solid transparent;
  }
  &.active {
    border-color: #5585e4;
  }
  &.h-type-1 {
    height: @cardHeight-1;
  }
  &.h-type-2 {
    height: @cardHeight-2;
  }
  .ant-card-head-title {
    height: 42px;
    padding: 8px 0 0;
    color: rgba(0, 0, 0, 0.8);
    line-height: 34px;
  }
  &-col.ant-col {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-self: flex-end;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
  }
}
