@import "../../../../../assets/style/media.less"; // 媒体查询
@import "../../../../../assets/style/classname.less"; // 类名
@iotChartPrefix: ~".iot-component-chart-dashboard";

@{iotChartPrefix} {
  &-wrap {
    width: 100%;
  }
  &-echarts-items {
    position: relative;
    display: grid;
    grid-row-gap: 24px;
    grid-column-gap: 24px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-bottom: 24px;
    padding: 24px;
  }
  &-echarts-item {
    display: flex;
    height: 144px;
    padding: 0 16px 0 16px;
  }
  &-echarts-item-left {
    width: 60%;
  }

  &-echarts-item-right {
    display: flex;
    flex-direction: column;
    width: 40%;
    padding-top: 32px;
    text-align: center;
  }

  &-echarts-item-title {
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
  }

  &-echarts-item-value {
    margin-bottom: 2px;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
  }

  &-echarts-item-bottom {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    height: 0;
    padding-left: 12px;

    &::before {
      position: absolute;
      top: 50%;
      left: 0;
      width: 4px;
      height: 12px;
      background-color: #ff595e;
      transform: translateY(-50%);
      content: " ";
    }
  }
}

@media (max-width: 1400px) {
  .echarts-items {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr !important;
  }
}
