@import "../../assets/style/media.less"; // 媒体查询
@import "../../assets/style/classname.less"; // 类名
#main-root @{TWBasePrefixCls} {

  // logo部分
  &-logo {
    display: flex;
    align-items: center;
    height: 100%;
    min-width: 452px;
  
    img {
      display: inline-block;
      height: 32px;
      vertical-align: middle;
    }
  
    h1 {
      display: inline-block;
      margin: 0 8px 0 16px;
      color: #fff;
      font-size: 18px;
      vertical-align: top;
      max-width: 270px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: normal;
    }
  }

  // 页面公共头部
  &-header {
    @headerH: 48px;

    display: flex;
    align-items: center;
    height: @headerH;
    line-height: @headerH;
    padding: 0 24px;

    &-menu {
      flex: 1;
      overflow: hidden;

      > ul {

        > li {
          height: @headerH;
          line-height: @headerH;
        }
      }
    }

    &-right {
      display: flex;
      align-items: center;
      justify-content: right;
      min-width: 220px;
      text-align: right;

      &_avatar {
        display: flex;
        align-items: center;
      }

      &_btn {
        font-size: 16px;
        cursor: pointer;
        color: #fff !important;
      }
    }
  }

  &-wrap {
    flex: 1;
    width: 100% !important;

    &_right {
      height: 100%;

      > .ant-spin-nested-loading {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        > .ant-spin-container {
          flex: 1;
          display: flex;
          flex-direction: column;
          overflow: hidden;
        }
      }
    }
  }

  // 左侧菜单
  &-sider {
    overflow: hidden;

    &_menu {
      // @width: 210px;
      // width: @width !important;
      // max-width: @width !important;
      // flex-basis: @width !important;
    }

    &_card {
      // @width: 400px;
      // width: @width !important;
      // max-width: @width !important;
      // flex-basis: @width !important;
    }
  }

  &-app-container {
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

html, body, #main-root {
  height: 100%;
}
