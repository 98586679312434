@import "../../../../../assets/style/media.less"; // 媒体查询
@import "../../../../../assets/style/classname.less"; // 类名
@iotFeaturesPrefix: ~".iot-component-features";

@{iotFeaturesPrefix} {
  display: flex;
  align-items: center;
  &-icon {
    .icon {
      color: rgba(85, 133, 228, 0.5);
    }
  }
  &-content {
    color: rgba(0, 0, 0, 0.4);
    font-size: 10px;
  }
}
