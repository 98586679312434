@import "../../../../../assets/style/media.less"; // 媒体查询
@import "../../../../../assets/style/classname.less"; // 类名
@iotStatisticPrefix: ~'.iot-component-description';

@{iotStatisticPrefix} {
  display: flex;
  align-items: center;
  &-label {
    margin-right: 8px;
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
  }
  &-content {
    color: rgba(0, 0, 0, 0.8);
    font-weight: 600;
    font-size: 12px;
  }
}
