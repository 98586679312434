@import "../../../../../assets/style/media.less"; // 媒体查询
@import "../../../../../assets/style/classname.less"; // 类名
@iotImagePrefix: ~".iot-component-image";

@{iotImagePrefix} {
  // 主要用于控制iconfont的大小，默认42px
  font-size: 42px;
  img {
    max-width: 100%;
  }
  .svg-icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
  }
  .icon {
    display: block;
    margin: 0 auto;
    color: #333;
    -webkit-transition: font-size 0.25s linear, width 0.25s linear;
    -moz-transition: font-size 0.25s linear, width 0.25s linear;
    transition: font-size 0.25s linear, width 0.25s linear;
  }
}
